"use client";
import Button from "@/components/common/button";
import Image from "next/image";
import Link from "next/link";
import { FC, useContext, useState } from "react";
import gbciLogo from "../../../public/gbci-logo.png";
import { AuthContext } from "@/context/AuthContext";
import { HamburgerMenu } from "./svgs/hamburguer-menu";
import useBreakpoint from "@/hooks/useBreakpoint";
import SignInModal from "@/components/sign-in-modal";
// import { PROFILE_IMAGES_URL, SERVER_API_URL } from "@/constants/server";
import random from "../../../public/images/figures/testimonals/default.jpg";
const PROFILE_IMAGES_URL = process.env.NEXT_PUBLIC_PROFILE_IMAGES_URL;
const Header: FC = () => {
  const { auth, logOut } = useContext(AuthContext);

  const [showToolSubMenu, setShowToolSubMenu] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [displaySignInModal, setDisplaySignInModal] = useState(false);

  const breakpoint = useBreakpoint();
  const isMobileViewport = breakpoint.sm || breakpoint.md || breakpoint.lg;

  function HeaderReset() {
    setShowToolSubMenu(false);
    setShowHamburgerMenu(false);
  }

  const NavBar = () => (
    <nav>
      <ul
        className={
          isMobileViewport
            ? "absolute px-page-xl bg-gradient-to-br from-slate-900 to-slate-700 left-0 right-0 flex flex-col p-8 gap-8 items-center text-xl"
            : "flex gap-4 items-center text-base h-[60px]"
        }
      >
        <li>
          <Link href="/">
            <button onClick={HeaderReset}>Home</button>
          </Link>
        </li>
        <li className="cursor-pointer">
          <button onClick={() => setShowToolSubMenu((prev) => !prev)}>
            Tools {showToolSubMenu ? <>&#9650;</> : <>&#9660;</>}
          </button>
        </li>
        {showToolSubMenu && isMobileViewport && (
          <li>
            <ul>
              <li>
                <Link href="tools/isa">
                  <button onClick={HeaderReset}>
                    Impetus Selection Assessment
                  </button>
                </Link>
              </li>
            </ul>
          </li>
        )}
        {/* <li>
          <Link href="/academy">
            <button onClick={HeaderReset}>Academy</button>
          </Link>
        </li> */}
        <li>
          <Link href="/games">
            <button onClick={HeaderReset}>Games</button>
          </Link>
        </li>
        {/* <li>
          <Link href="/insights">
            <button onClick={HeaderReset}>Insights</button>
          </Link>
        </li> */}

        <li>
          <Link href="/about">
            <button onClick={HeaderReset}>About Us</button>
          </Link>
        </li>
        <li>
          <Link href="/#contact">
            <button onClick={HeaderReset}>Contact</button>
          </Link>
        </li>
        <li>
          {auth.isLoggedIn ? (
            <Link href="/profile">
              <button
                className="h-[60px] w-[91px] pl-2 flex gap-2 items-center"
                onClick={HeaderReset}
              >
                {auth.currentUser && auth.currentUser.photo_url ? (
                  <img
                    className="w-10 h-10 rounded-full"
                    width={40}
                    height={40}
                    src={`${PROFILE_IMAGES_URL}${auth.currentUser.photo_url}`}
                    alt=""
                  />
                ) : (
                  <Image
                    className="w-10 h-10 rounded-full"
                    width={40}
                    height={40}
                    src={random}
                    alt=""
                  />
                )}

                <p>Profile</p>
              </button>
            </Link>
          ) : (
            <Button
              size="large"
              onClick={() => {
                setDisplaySignInModal(true);
              }}
            >
              Sign In
            </Button>
            // <></>
          )}
        </li>
        {auth.isLoggedIn && (
          <li className="px-8 xl:px-0 xl:absolute xl:right-8">
            <button onClick={() => logOut()}>Sign Out</button>
          </li>
        )}
      </ul>
    </nav>
  );

  const MenuItem: FC<{ header: string; nav: { [x: string]: string }[] }> = ({
    header,
    nav,
  }) => {
    return (
      <div className="">
        <h2 className="text-2xl font-bold">{header}</h2>
        <nav className="text-xl">
          <ul className="flex flex-col">
            {nav.map((item) => {
              const [navItem, navLink] = Object.entries(item)[0];

              return (
                <li key={navLink}>
                  <Link className="hover:underline" href={navLink}>
                    <button onClick={HeaderReset}>{navItem}</button>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  };

  const Menu: FC = () => {
    return (
      <div className="absolute px-page-xl py-8 flex gap-32 bg-gradient-to-br from-slate-700 to-gbci-primary left-0 right-0">
        <MenuItem
          header="Tools"
          nav={[
            { "Impetus Selection Assessment": "/tools/isa" },
            // { "Leadership Energy Assessment": "/leadership-energy-assesment" },
          ]}
        />
      </div>
    );
  };

  return (
    <header className="relative bg-gradient-to-br from-slate-900 to-slate-700 text-white shadow-md z-50 border-t-8 border-gbci-accent">
      <div className="flex justify-between items-center px-page-xl py-4">
        <Image
          className="w-[150px] h-[34px] translate-y-1"
          src={gbciLogo}
          alt="GBCI Group"
        />

        {isMobileViewport ? (
          <div className="">
            <button
              className="p-2 rounded-md "
              onClick={() => setShowHamburgerMenu((prev) => !prev)}
            >
              <HamburgerMenu className="fill-white w-10 h-10" />
            </button>
          </div>
        ) : (
          <NavBar />
        )}
      </div>

      {showToolSubMenu && !isMobileViewport && <Menu />}

      {showHamburgerMenu && isMobileViewport && <NavBar />}

      {displaySignInModal && (
        <SignInModal handleClose={() => setDisplaySignInModal(false)} />
      )}
    </header>
  );
};

export default Header;
