import ReactDOM from "react-dom";
import { FC, ReactNode } from "react";

export interface ModalProps {
  children: ReactNode;
  handleClose: () => void;
  header?: string;
  size?: "small" | "medium" | "large" | "page";
}

const sizeStyle = {
  small: "md:max-w-[560px]",
  medium: "md:max-w-[780px]",
  large: "md:max-w-[1000px]",
  page: "md:max-w-full",
};

const Modal: FC<ModalProps> = ({
  children,
  header,
  handleClose,
  size = "medium",
}) => {
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Ensure clicks on the modal content do not propagate to the backdrop
    e.stopPropagation();
    // Close the modal if the backdrop (dark area) is clicked
    handleClose();
  };

  return ReactDOM.createPortal(
    <div
      onClick={handleBackdropClick}
      className="fixed z-50 top-0 left-0 right-0 bottom-0 bg-black/55 flex flex-col items-center p-8 md:pt-16"
    >
      <div
        onClick={(e) => e.stopPropagation()} // Prevent clicks within modal from closing it
        className={`animate-fadein bg-white rounded-md overflow-auto w-full max-w-full ${sizeStyle[size]}`}
      >
        <div className="flex justify-between items-center px-4 pt-4 md:px-8">
          <p className="text-2xl font-bold text-center">{header}</p>
          <button className="self-end" onClick={handleClose}>
            &#10005;
          </button>
        </div>
        <div className="p-4 md:pb-12 md:px-8">{children}</div>
      </div>
    </div>,
    document.getElementById("modal-root")!,
  );
};

export default Modal;
