import { FC, useContext, useState } from "react";
import { FormProps } from ".";
import Button from "../common/button";
import { AuthContext, UserPayload } from "@/context/AuthContext";

enum Alerts {
  NO_ALERT,
  USER_ALREADY_EXISTS,
  SERVER_ERROR,
}
const SERVER_API_URL = process.env.NEXT_PUBLIC_SERVER_API_URL;

const ResendForm: FC<FormProps> = ({ changeForm, onSuccess }) => {
  const { logIn } = useContext(AuthContext);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
  });
  const [showAlert, setShowAlert] = useState(Alerts.NO_ALERT);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.id]: "" }); // Remove error on type
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = {
      email: "",
    };

    let register_errors = 0;

    if (!formData.email) {
      errors.email = "Please enter your email.";
      register_errors = register_errors + 1;
    } else {
      if (!formData.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        errors.email = "Please enter a valid email address";
        register_errors = register_errors + 1;
      }
    }

    if (register_errors != 0) {
      setFormErrors(errors);
      return;
    }

    setDisableButton(true);

    const credentials = {
      email: formData.email,
    };

    const sendCode = async () => {
      try {
        const res = await fetch(
          `${SERVER_API_URL}/api/v1/users/resend/verification`,
          {
            body: JSON.stringify(credentials),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          },
        );

        if (res.status >= 200 && res.status <= 299) {
          const data = await res.json();

          setDisableButton(false);
          onSuccess();
        } else {
          const error = await res.json();
          const detail: string = error["detail"];
          setDisableButton(false);
          if (detail.includes("exist")) {
            setShowAlert(Alerts.USER_ALREADY_EXISTS);
          } else {
            setShowAlert(Alerts.SERVER_ERROR);
          }
        }
      } catch (e) {
        setShowAlert(Alerts.SERVER_ERROR);
      }
    };

    sendCode();
  };

  return (
    <div>
      <p className="text-3xl font-bold text-center">Verify account</p>
      <p className="mt-2 text-xl text-slate-400 mb-8 text-center">
        Provide your email to re-send the verification code
      </p>

      <form className="w-full flex flex-col gap-2" onSubmit={handleSubmit}>
        <label className="text-slate-400 font-bold flex flex-col">
          EMAIL:
          <input
            className="border-2 rounded-lg p-2 w-full"
            placeholder="Email address"
            type="text"
            id="email"
            value={formData.email}
            onChange={handleChange}
          />
          <p
            className={
              formErrors.email ? "text-red-500" : "text-transparent select-none"
            }
          >
            {formErrors.email || "z"}
          </p>
        </label>
        <Button size="large" type="submit" disabled={disableButton}>
          Send verification code
        </Button>
      </form>

      {showAlert !== Alerts.NO_ALERT && (
        <div className="mt-8 p-4 text-red-400 bg-red-100 border border-red-400 rounded-md">
          <p>
            {showAlert == Alerts.USER_ALREADY_EXISTS
              ? "User already exists. Please log in"
              : "Server error. Please try again"}
          </p>
        </div>
      )}
    </div>
  );
};

export default ResendForm;
