import { FC, useContext, useState } from "react";
import { FormProps } from ".";
import Button from "../common/button";
import { AuthContext, UserPayload } from "@/context/AuthContext";
import React from "react";

enum Alerts {
  NO_ALERT,
  USER_ALREADY_EXISTS,
  SERVER_ERROR,
}
const SERVER_API_URL = process.env.NEXT_PUBLIC_SERVER_API_URL;

const RegisterForm: FC<FormProps> = ({ changeForm, onSuccess }) => {
  const { logIn } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    phone_country_zone: "+1",
    phone_number: "",
    timezone: "",
    avatar_url: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    phone_country_zone: "",
    phone_number: "",
    timezone: "",
    avatar_url: "",
  });

  const [formDataSecret, setFormDataSecret] = useState({
    verification_code: "",
  });
  const [formErrorsSecret, setFormErrorsSecret] = useState({
    verification_code: "",
  });
  const [showTokenfield, setShowTokenfield] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(Alerts.NO_ALERT);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    // setFormData({ ...formData, [e.target.id]: e.target.value });
    const { id, value } = e.target;

    // Only allow numbers in the phone field
    if (id === "phone_number") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
      setFormData({ ...formData, [id]: numericValue });
    } else {
      setFormData({ ...formData, [id]: value });
    }
    setFormErrors({ ...formErrors, [e.target.id]: "" }); // Remove error on type
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    // setFormData({ ...formData, [e.target.id]: e.target.value });
    const { id, value } = e.target;

    setFormData({ ...formData, [id]: value });
    setFormErrors({ ...formErrors, [e.target.id]: "" }); // Remove error on type
  };

  const handleChangeSecret = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormDataSecret({ ...formDataSecret, [e.target.id]: e.target.value });
    setFormErrorsSecret({ ...formErrorsSecret, [e.target.id]: "" }); // Remove error on type
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = {
      name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      phone_country_zone: "",
      phone_number: "",
      timezone: "",
      avatar_url: "",
    };

    let register_errors = 0;

    if (!formData.name) {
      errors.name = "Please enter your name.";
      register_errors = register_errors + 1;
    }

    if (!formData.last_name) {
      errors.last_name = "Please enter your last name.";
      register_errors = register_errors + 1;
    }

    if (!formData.email) {
      errors.email = "Please enter your email.";
      register_errors = register_errors + 1;
    } else {
      if (!formData.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        errors.email = "Please enter a valid email address";
        register_errors = register_errors + 1;
      }
    }

    if (!formData.password) {
      errors.password = "Please enter your password.";
      register_errors = register_errors + 1;
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long.";
      register_errors = register_errors + 1;
    } else if (!formData.password.match(/[a-z]/)) {
      errors.password = "Password must contain at least one lowercase letter";
      register_errors = register_errors + 1;
    } else if (!formData.password.match(/[A-Z]/)) {
      errors.password = "Password must contain at least one uppercase letter";
      register_errors = register_errors + 1;
    } else if (!formData.password.match(/[0-9]/)) {
      errors.password = "Password must contain at least one digit";
      register_errors = register_errors + 1;
    } else if (!formData.password.match(/[@$!?#%*?&]/)) {
      errors.password =
        "Password must contain at least one of the following symbols: @$!?#%*?&";
      register_errors = register_errors + 1;
    }

    if (!formData.confirm_password) {
      errors.confirm_password = "Please confirm password.";
      register_errors = register_errors + 1;
    } else if (formData.confirm_password != formData.password) {
      errors.confirm_password = "Password is not the same";
      register_errors = register_errors + 1;
    }

    if (!formData.phone_number) {
      errors.phone_number = "Please enter your phone.";
      register_errors = register_errors + 1;
    } else if (formData.phone_number.length < 10) {
      errors.phone_number = "Phone number must be at least 10 characters long.";
      register_errors = register_errors + 1;
    }

    if (register_errors != 0) {
      setFormErrors(errors);
      return;
    }

    setDisableButton(true);

    const credentials = {
      email: formData.email,
      password: formData.password,
      attributes: {
        name: formData.name,
        last_name: formData.last_name,
        phone_country_zone: formData.phone_country_zone,
        phone_number: formData.phone_number,
        timezone: formData.timezone,
        avatar_url: formData.avatar_url,
      },
    };

    const registerUser = async () => {
      try {
        const res = await fetch(`${SERVER_API_URL}/api/v1/users/`, {
          body: JSON.stringify(credentials),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        });

        if (res.status >= 200 && res.status <= 299) {
          const data = await res.json();
          setDisableButton(false);
          setShowTokenfield(true);
          // onSuccess();
        } else {
          const error = await res.json();
          const detail: string = error["detail"];
          setDisableButton(false);
          if (detail.includes("exist")) {
            setShowAlert(Alerts.USER_ALREADY_EXISTS);
          } else {
            setShowAlert(Alerts.SERVER_ERROR);
          }
        }
      } catch (e) {
        setShowAlert(Alerts.SERVER_ERROR);
      }
    };

    registerUser();
  };

  const handleSubmitVerify = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = {
      email: "",
      verification_code: "",
    };

    let register_errors = 0;

    if (!formData.email) {
      errors.email = "Please enter your email.";
      register_errors = register_errors + 1;
    } else {
      if (!formData.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        errors.email = "Please enter a valid email address";
        register_errors = register_errors + 1;
      }
    }

    if (!formDataSecret.verification_code) {
      errors.verification_code = "Please enter your verification code.";
      register_errors = register_errors + 1;
    }

    if (register_errors != 0) {
      setFormErrorsSecret(errors);
      return;
    }

    setDisableButton(true);

    const credentials = {
      email: formData.email,
      verification_code: formDataSecret.verification_code,
    };

    const verifyUser = async () => {
      try {
        const res = await fetch(`${SERVER_API_URL}/api/v1/users/verify/user`, {
          body: JSON.stringify(credentials),
          headers: {
            "Content-Type": "application/json",
          },
          method: "PUT",
        });

        if (res.status >= 200 && res.status <= 299) {
          const data = await res.json();
          setDisableButton(false);
          onSuccess();
        } else {
          const error = await res.json();
          const detail: string = error["detail"];
          setDisableButton(false);
          if (detail.includes("exist")) {
            setShowAlert(Alerts.USER_ALREADY_EXISTS);
          } else {
            setShowAlert(Alerts.SERVER_ERROR);
          }
        }
      } catch (e) {
        setShowAlert(Alerts.SERVER_ERROR);
      }
    };

    verifyUser();
  };

  return (
    <div>
      {showTokenfield ? (
        <>
          <p className="text-3xl font-bold text-center">Verify account</p>
          <p className="mt-2 text-xl text-slate-400 mb-8 text-center">
            Please check you email for the verification code and verify your
            email to finish creating your account
          </p>

          <form
            className="w-full flex flex-col gap-2"
            onSubmit={handleSubmitVerify}
          >
            <label className="text-slate-400 font-bold flex flex-col">
              VERIFICATION CODE:
              <input
                className="border-2 rounded-lg p-2 w-full"
                placeholder="Verification code"
                type="text"
                id="verification_code"
                value={formDataSecret.verification_code}
                onChange={handleChangeSecret}
                autoFocus={true}
              />
              <p
                className={
                  formErrorsSecret.verification_code
                    ? "text-red-500"
                    : "text-transparent select-none"
                }
              >
                {formErrorsSecret.verification_code || "z"}
              </p>
            </label>
            <Button size="large" type="submit" disabled={disableButton}>
              Verify account
            </Button>
          </form>
        </>
      ) : (
        <>
          <p className="text-3xl font-bold text-center">Register</p>
          <p className="mt-2 text-xl text-slate-400 mb-8 text-center">
            Create your account or{" "}
            <button
              onClick={() => changeForm("login")}
              className=" text-gbci-accent font-bold"
            >
              log in
            </button>{" "}
            if you already have an account
          </p>

          <form className="w-full flex flex-col gap-2" onSubmit={handleSubmit}>
            <label className="text-slate-400 font-bold flex flex-col">
              NAME:
              <input
                className="border-2 rounded-lg p-2 w-full"
                placeholder="Name"
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                autoFocus={true}
              />
              <p
                className={
                  formErrors.name
                    ? "text-red-500"
                    : "text-transparent select-none"
                }
              >
                {formErrors.name || "z"}
              </p>
            </label>

            <label className="text-slate-400 font-bold flex flex-col">
              LAST NAME:
              <input
                className="border-2 rounded-lg p-2 w-full"
                placeholder="Last Name"
                type="text"
                id="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
              <p
                className={
                  formErrors.last_name
                    ? "text-red-500"
                    : "text-transparent select-none"
                }
              >
                {formErrors.last_name || "z"}
              </p>
            </label>

            <label className="text-slate-400 font-bold flex flex-col">
              PHONE NUMBER:
              <div className="w-full flex">
                <select
                  className="border-2 rounded-lg p-2"
                  id="phone_country_zone"
                  value={formData.phone_country_zone}
                  onChange={handleChangeSelect}
                >
                  <option value="+1">+1 (US)</option>
                  <option value="+52">+52 (MXN)</option>
                </select>
                <input
                  className="border-2 rounded-lg p-2 w-full"
                  placeholder="Phone Number"
                  type="tel"
                  id="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              </div>
              <p
                className={
                  formErrors.phone_number
                    ? "text-red-500"
                    : "text-transparent select-none"
                }
              >
                {formErrors.phone_number || "z"}
              </p>
            </label>

            <label className="text-slate-400 font-bold flex flex-col">
              EMAIL:
              <input
                className="border-2 rounded-lg p-2 w-full"
                placeholder="Email address"
                type="text"
                id="email"
                value={formData.email}
                onChange={handleChange}
              />
              <p
                className={
                  formErrors.email
                    ? "text-red-500"
                    : "text-transparent select-none"
                }
              >
                {formErrors.email || "z"}
              </p>
            </label>

            <label className="text-slate-400 font-bold flex flex-col">
              PASSWORD:
              <input
                type={showPassword ? "text" : "password"}
                className="border-2 rounded-lg p-2 w-full"
                placeholder="Password"
                id="password"
                value={formData.password}
                onChange={handleChange}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="inset-y-0 right-0 px-1 flex items-center focus:outline-none"
              >
                {showPassword ? "Hide password" : "Show password"}
              </button>
              <p
                className={
                  formErrors.password
                    ? "text-red-500"
                    : "text-transparent select-none"
                }
              >
                {formErrors.password || "z"}
              </p>
            </label>

            <label className="text-slate-400 font-bold flex flex-col">
              CONFIRM PASSWORD:
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="border-2 rounded-lg p-2 w-full"
                placeholder="Confirm Password"
                id="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
              />
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className="inset-y-0 right-0 px-1 flex items-center focus:outline-none"
              >
                {showConfirmPassword ? "Hide password" : "Show password"}
              </button>
              <p
                className={
                  formErrors.confirm_password
                    ? "text-red-500"
                    : "text-transparent select-none"
                }
              >
                {formErrors.confirm_password || "z"}
              </p>
            </label>

            <div className="flex justify-between flex-col-reverse md:flex-row gap-6">
              <div className=" text-slate-400 font-bold text-right mb-4">
                Already registered?{" "}
                <button
                  className="text-gbci-accent"
                  onClick={() => changeForm("resend")}
                >
                  Verify account
                </button>
              </div>
            </div>

            <Button size="large" type="submit" disabled={disableButton}>
              Create account
            </Button>
          </form>
        </>
      )}
      {showAlert !== Alerts.NO_ALERT && (
        <div className="mt-8 p-4 text-red-400 bg-red-100 border border-red-400 rounded-md">
          <p>
            {showAlert == Alerts.USER_ALREADY_EXISTS
              ? "User already exists. Please log in"
              : "Server error. Please try again"}
          </p>
        </div>
      )}
    </div>
  );
};

export default RegisterForm;
